import React, { useEffect } from 'react';
import { AppContext, AppInitialProps } from 'next/app';
import Head from 'next/head';
import '../reorder.scss';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import '../utils/htmledit.css';

// AntD styles
import 'antd/dist/antd.css';

import Error from 'next/error';

import * as Sentry from '@sentry/browser';
import { useRouter } from 'next/router';
import Website from '../components/layouts/Website';

import '../utils/Clock.css';
import '../utils/Calendar.css';
import '../utils/DateTimePicker.css';

Sentry.init({
    dsn: 'https://2f2330430a1a474f97c26802fe203ce5@o145128.ingest.sentry.io/1354414',
    environment: process.env.ENV === 'production' ? 'production' : 'development',
});

const App = ({ Component, pageProps }: AppContext & AppInitialProps): React.ReactElement => {
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }, []);

    const router = useRouter();
    let showNav = true;
    if (router.pathname === '/login' || router.pathname === '/register') {
        showNav = false;
    }

    try {
        return (
            <>
                <Head>
                    <title>Sri Lanka Olympiad Mathematics Foundation</title>

                    <meta charSet="utf-8" />
                    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                    <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                    />
                    <meta name="theme-color" content="#3f51b5" />
                    <link
                        rel="stylesheet"
                        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
                    />
                    <link rel="icon" href="/icon32.png" sizes="32x32" />
                    <link rel="icon" href="/icon192.png" sizes="192x192" />
                    <link rel="apple-touch-icon-precomposed" href="/icon180.png" />
                </Head>

                {showNav ? (
                    <Website>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <Component {...pageProps} />
                    </Website>
                ) : (
                    <>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <Component {...pageProps} />
                    </>
                )}

                <style>{'body { background-color: white; }'}</style>
            </>
        );
    } catch (error) {
        Sentry.withScope(scope => {
            Object.keys(error).forEach(key => {
                scope.setExtra(key, error[key]);
            });

            Sentry.captureException(error);
        });

        if (process.browser) {
            return <Error statusCode={500} />;
        }
        error.code = 'ENOENT';
        throw error;
    }
};

export default App;
