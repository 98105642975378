/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';

// Antd Components
import { Row, Col, Image, Space, Button, Typography as AntTypography } from 'antd';

import { TitleProps } from 'antd/lib/typography/Title';

import styled from 'styled-components';

const { Paragraph } = AntTypography;

// const ColorPrimary = '#3f51b5';

// styled components
const FooterInfoTitle = styled(Paragraph)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        color: #5e5e5e;
        font-weight: 500;
        margin: 5px 0px;
        font-size: 17px;
    }
`;

const FooterInfoSubtitle = styled(Paragraph)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        color: #5e5e5e;
        font-weight: 500;
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: 15px;
    }
`;

const FooterInfo = styled(Paragraph)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        color: #5e5e5e;
        margin: 0px;
        font-size: 15px;
    }
`;

const FooterCopyright = styled(Paragraph)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        color: #5e5e5e;
        @media only screen and (max-width: 599px) {
            font-size: 13px;
        }
        font-size: 16px;
    }
`;

const logos = [
    {
        title: 'WORLD INTERNATIONAL MATHEMATICAL OLYMPIAD',
        src: 'https://slomf.s3-ap-southeast-1.amazonaws.com/logos/logo-wimo.png',
        url: 'https://www.worldimo.org',
    },
    {
        title: 'THAILAND INTERNATIONAL MATHEMATICAL OLYMPIAD',
        src: '/images/footer-logos/TIMO.png',
        url: 'https://www.thaiimo.com/',
    },
    {
        title: 'HONG KONG INT’L MATHEMATICAL OLYMPIAD',
        src: 'https://slomf.s3-ap-southeast-1.amazonaws.com/logos/logo-hkimo.png',
        url: 'https://chiuchang.org/imc/en/home-en/',
    },
    {
        title: 'HONG KONG INT’L SCIENCE OLYMPIAD',
        src: 'https://slomf.s3.ap-southeast-1.amazonaws.com/assets/6-1625124778784.png',
    },
    {
        title: 'DOKA',
        src: '/images/footer-logos/DOKA.png',
        url: 'https://doka-math.org/',
    },
    {
        title: 'INTERNATIONAL MATHEMATICAL OLYMPIAD',
        src: 'https://slomf.s3-ap-southeast-1.amazonaws.com/logos/logo-imo.png',
        url: 'https://www.imo-official.org',
    },
    {
        title: 'VANDA ',
        src: '/images/footer-logos/Vanda.png',
        url: 'https://vanda.sg/',
    },
    {
        title: 'Dr.CT Informatics',
        src: 'https://slomf.s3.ap-southeast-1.amazonaws.com/assets/DrCT_Logo_Updated_17Aug20.png',
    },
    {
        title: 'SINGA ',
        src: '/images/footer-logos/Singa.png',
        url: 'https://singamath.com/',
    },
    {
        title: 'HONG KONG INT’L COMPUTING OLYMPIAD',
        src: 'https://slomf.s3.ap-southeast-1.amazonaws.com/assets/6-1625125124867.png',
    },
    {
        title: 'SASMO',
        src: '/images/footer-logos/SASMO.png',
        url: 'https://sasmo.sg/',
    },
    // {
    //     title: '',
    //     src: '/images/champion.jpeg',
    // },
    // {
    //     title: '',
    //     src: '/images/slomf.jpeg',
    // },
    // {
    //     title: '',
    //     src: '/images/science.jpeg',
    // },

    // {
    //     title: 'INT’L MATHEMATICS COMPETITION',
    //     src: 'https://slomf.s3-ap-southeast-1.amazonaws.com/logos/logo-imc.png',
    //     url: 'https://chiuchang.org/imc/en/home-en/',
    // },
    // {
    //     title: 'OLYMPIAD CHAMPION',
    //     src: '/images/OCEC-Logo.png',
    //     url: 'https://www.olympiadchampion.com/',
    // },

    // {
    //     title: 'SRI LANKA TECHNOLOGICAL CAMPUS',
    //     src: '/images/logo-md-300x68.png',
    //     url: 'https://sltc.ac.lk/',
    // },
    // {
    //     title: '',
    //     src: '/images/4.-LOGO-EPSILON-DELTA-SOCIETY-300x300.jpg',
    //     url: 'https://science.cmb.ac.lk/life-at-fos/student-organizations/epsilon-delta-society/',
    // },
    // {
    //     title: 'ASIAN PACIFIC MATHEMATICAL OLYMPIAD',
    //     src: 'https://slomf.s3-ap-southeast-1.amazonaws.com/logos/logo-apmo.png',
    //     url: 'http://www.worldimo.org/',
    // },
    // {
    //     title: 'SIMCO',
    //     src: '/images/footer-logos/SIMCO.png',
    //     url: 'https://simoc.sg/',
    // },

    // {
    //     title: 'AMO ',
    //     src: '/images/footer-logos/AMO.jpg',
    //     url: 'https://www.amo.sg/',
    // },
    // {
    //     title: 'HKIMO ',
    //     src: '/images/footer-logos/HKIMO.png',
    //     url: 'https://www.hongkongimo.com/',
    // },
    // {
    //     title: 'SIMCC',
    //     src: '/images/SIMCC-LOGO.jpg',
    //     url: 'hhttps://simcc.org/',
    // },

    // {
    //     title: 'THAILAND INTERNATIONAL MATHEMATICAL OLYMPIAD',
    //     src: 'https://slomf.s3-ap-southeast-1.amazonaws.com/logos/logo-timo.png',
    //     url: 'https://www.thaiimo.com/',
    // },
];

// styles
const footerContainerStyles = {
    width: '100%',
    background: 'white',
    borderTop: '1px solid #e6e6e6',
    overflow: 'hidden',
};

const footerStyles = {
    padding: '2% 8% ',
};

const footerCopyrightStyles = {
    width: '100%',
    padding: '1% 15%',
    alignItems: 'center',
};

type TProps = {
    minimal: boolean;
};

const WebsiteFooter = ({ minimal }: TProps): JSX.Element => {
    return (
        <footer style={{ width: '100%' }}>
            <div style={footerContainerStyles}>
                {!minimal && (
                    <>
                        <Row gutter={[8, 8]} style={footerStyles} justify="center">
                            <Col xs={24} md={8}>
                                <FooterInfoTitle>SRI LANKA OLYMPIAD MATHEMATICS FOUNDATION (SLOMF)</FooterInfoTitle>
                                <br />

                                <FooterInfoSubtitle style={{ marginTop: 0 }}>SLOMF Office :</FooterInfoSubtitle>

                                <FooterInfo style={{ marginLeft: 20 }}>No. 35/1-2, (Second Floor)</FooterInfo>
                                <FooterInfo style={{ marginLeft: 20 }}>Suneththradevi Road,</FooterInfo>
                                <FooterInfo style={{ marginLeft: 20 }}>Kohuwala, Nugegoda</FooterInfo>

                                <br />
                            </Col>
                            <Col xs={24} md={8}>
                                <FooterInfo>
                                    ☎️ <img src="/images/whatsapp.png" alt="" width={18} />
                                    Hotline / Secretary (Sinhala/Tamil/English):
                                </FooterInfo>
                                <FooterInfo>WhatsApp Call/Text Preferred: +94 76 149 6243 / +94 76 455 3217</FooterInfo>
                                <br />
                                <FooterInfo>
                                    <img src="/images/whatsapp.png" alt="" width={18} />
                                    Director of Operations/ SLOMF (Sinhala/English):
                                </FooterInfo>
                                <FooterInfo>WhatsApp Text Only: +94 76 445 5004</FooterInfo>
                                <br />
                                <FooterInfo>
                                    <img src="/images/whatsapp.png" alt="" width={18} />
                                    CEO/ SLOMF (Sinhala/English):
                                </FooterInfo>
                                <FooterInfo>WhatsApp Text Only: +94 76 455 3215</FooterInfo>
                                <br />
                            </Col>
                            <Col xs={24} md={8}>
                                <FooterInfoSubtitle style={{ marginTop: 0 }}>Opening Hours:</FooterInfoSubtitle>

                                <FooterInfo style={{ marginLeft: '4ch' }}>Tuesday - Friday: 10 am – 3 pm</FooterInfo>
                                <FooterInfo style={{ marginLeft: '4ch' }}>Saturday: 10 am – 4 pm</FooterInfo>

                                <br />
                                {/* eslint-disable-next-line jsx-a11y/accessible-emoji*/}
                                <FooterInfo>📧 info@slmathsolympiad.org</FooterInfo>
                                <br />
                                <FooterInfo>
                                    <img src="/images/facebook.png" alt="" width={18} />{' '}
                                    <a href="http://www.facebook.com/slmathsolympiad/">
                                        https://www.facebook.com/slmathsolympiad/
                                    </a>
                                </FooterInfo>
                            </Col>
                        </Row>
                        <Row align="middle" justify="center" gutter={4}>
                            {logos.map(logo => (
                                <Col xs={4} md={4} key={`logo-${logo.title}`}>
                                    <Image
                                        width={150}
                                        src={logo.src}
                                        preview={{
                                            visible: false,
                                            mask: logo.url ? (
                                                <Space direction="vertical" align="center">
                                                    <Button href={logo.url} target="blank" type="primary">
                                                        Visit
                                                    </Button>
                                                </Space>
                                            ) : (
                                                undefined
                                            ),
                                        }}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </>
                )}
            </div>
            {/* Footer copyright */}

            <Row align="middle" justify="center" style={footerCopyrightStyles}>
                <Button
                    type="link"
                    color="inherit"
                    href="https://zynch.com/"
                    style={{ padding: '0px 4px', lineHeight: '27.5px' }}>
                    <FooterCopyright>
                        Powered by zynch Solutions{' '}
                        <span role="img" aria-label="zynch">
                            ⚡️
                        </span>
                    </FooterCopyright>
                </Button>

                <FooterCopyright style={{ marginBottom: 0, padding: '0px 4px' }}>
                    &copy; SLOMF {new Date().getFullYear()}{' '}
                </FooterCopyright>

                <Button
                    type="link"
                    color="inherit"
                    href="mailto:info@slmathsolympiad.org"
                    style={{ padding: '0px 4px', lineHeight: '27.5px' }}>
                    <FooterCopyright> info@slmathsolympiad.org </FooterCopyright>
                </Button>
            </Row>
        </footer>
    );
};

export default WebsiteFooter;
