import { AxiosResponse } from 'axios';
import api from './_config';
import { TRegisterPayload, TRegisterResponse, TPhoneVerifyResponse, TProfile, TTeacher } from '../types/user';
import { TLoginPayload, TLoginResponse } from '../pages/api/auth_login';

export default {
    get: async (): Promise<AxiosResponse<TProfile[]>> => api.get<TProfile[]>('/user_profile'),

    login: async (data: TLoginPayload): Promise<AxiosResponse<TLoginResponse>> =>
        api.post<TLoginResponse>('/auth_login', data),

    register: async (data: TRegisterPayload): Promise<AxiosResponse<TRegisterResponse>> =>
        api.post<TRegisterResponse>('/auth_register', data),

    getVerifyCode: async (phone: string): Promise<AxiosResponse<TPhoneVerifyResponse>> =>
        api.post<TPhoneVerifyResponse>('/auth_code', { phone }),

    getResetCode: async (phone: string): Promise<AxiosResponse<void>> => api.post<void>('/user_resetcode', { phone }),

    setNewPassword: async ({
        code,
        password,
        phone,
    }: {
        code: string;
        password: string;
        phone: string;
    }): Promise<AxiosResponse<void>> => api.post<void>('/user_passwordreset', { phone, code, password }),

    getTeachersToApprove: async (page?: string, search?: string): Promise<AxiosResponse<TTeacher[]>> =>
        api.post<TTeacher[]>('/teacher_getawaitingapproval', { page, search }),

    editTeacherApproval: async (user: number, approve: number, phone: string): Promise<AxiosResponse<void>> =>
        api.post<void>('/teacher_editapproval', { user, approve, phone }),

    addUserPhoto: async (applicant: string, imgdata: string): Promise<AxiosResponse<void>> =>
        api.post<void>('/applicant_addphoto', { applicant, imgdata }),
};
